<template>
  <b-form @submit.prevent="submit" autocomplete="off">
    <b-form-group label="Nombre">
      <b-form-input
        autofocus
        ref="nombre"
        required
        type="text"
        v-model="indicador.nombre"
        placeholder="Nombre del indicador"
      ></b-form-input>
    </b-form-group>
    <b-form-group :label="$t('Naturaleza')">
      <b-form-select required v-model="indicador.naturaleza">
        <b-form-select-option v-for="naturaleza in naturalezas" :value="naturaleza.value" :key="naturaleza.value">
          {{ naturaleza.text }}
        </b-form-select-option>
      </b-form-select>
    </b-form-group>

    <b-form-group v-if="isRezagado" :label="$t('Indicadores Adelantados')">
      <b-form-select required v-model="indicadorAdelantadoId" :options="comboIndicadoresAdelantados"> </b-form-select>
    </b-form-group>

    <b-form-group :label="$t('Tipo de indicador')">
      <b-form-select required v-model="indicador.tipoIndicador" :options="tiposDeIndicador"> </b-form-select>
    </b-form-group>

    <b-form-group v-if="isOrigenDatos" :label="$t('Origen de datos')">
      <b-form-select v-model="indicador.origenDatosId" :options="optionsOrigenDatos" />
    </b-form-group>

    <b-form-group label="Estado">
      <b-form-checkbox v-model="indicador.activo" switch value="1" unchecked-value="0">
        <b v-if="indicador.activo == true">Activo</b>
        <b v-else>Inactivo</b>
      </b-form-checkbox>
    </b-form-group>
    <botonera-edicion url-cancelar="/administracion/indicadores"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";
import * as apiIndicadores from "@/services/indicadores.js";
import * as apiOrigenesDatos from "@/services/origenesDatos.js";
import * as NaturalezaIndicador from "@/enum/NaturalezaIndicador";
import * as TipoIndicador from "@/enum/TipoIndicador";

export default {
  props: {
    indicador: Object,
    relacionados: {
      type: Array,
      default: () => [],
    },
  },
  components: {},
  computed: {
    isRezagado() {
      return this.indicador.naturaleza == NaturalezaIndicador.REZAGADO;
    },
    isOrigenDatos() {
      return this.indicador.tipoIndicador == TipoIndicador.DATASOURCE;
    },
    isImportacion() {
      return this.indicador.tipoIndicador == TipoIndicador.IMPORTACION;
    },
    comboIndicadoresAdelantados() {
      return this.indicadoresAdelantados.map((x) => {
        return {
          text: x.nombre,
          value: x.id,
        };
      });
    },
  },
  data() {
    return {
      naturalezas: NaturalezaIndicador.combo,
      indicadoresAdelantados: [],
      origenIndicador: Object.freeze({
        IMPORTACION: 2,
      }),
      optionsOrigenDatos: [],
      documento: {},
      indicadorAdelantadoId: 0,
      tiposDeIndicador: [
        { value: TipoIndicador.MANUAL, text: "Manual" },
        { value: TipoIndicador.DATASOURCE, text: "Origen de datos" },
        // { value: TipoIndicador.IMPORTACION, text: "Importación" },
      ],
    };
  },
  methods: {
    async listarAdelantados() {
      const response = await apiIndicadores.listarAdelantados();
      this.indicadoresAdelantados = response.indicadores;
    },

    async listarIncidentes() {
      const response = await apiOrigenesDatos.listarIncidentes();
      this.origenesIncidentes = response.origenesIncidentes;
    },

    async generarOptionsOrigenDatos() {
      function normalizarCombo(items) {
        return items.map((x) => {
          return { value: x.id, text: x.nombre };
        });
      }

      const responseChecklist = await apiOrigenesDatos.listarChecklist();
      const childrenChecklist = normalizarCombo(responseChecklist);

      const responseIncidentes = await apiOrigenesDatos.listarIncidentes();
      const childrenIncidentes = normalizarCombo(responseIncidentes);

      this.optionsOrigenDatos = [
        {
          text: "-",
          value: "",
        },
        {
          text: "CheckList",
          options: childrenChecklist,
        },
        {
          text: "Incidentes",
          options: childrenIncidentes,
        },
      ];
    },
    submit() {
      const relacionados = [];

      // Por ahora solo se admite un indicador relacionado, pero seguramente
      // se permitan más
      if (this.indicadorAdelantadoId > 0) {
        relacionados.push(this.indicadorAdelantadoId);
      }

      this.$emit("submit", {
        indicador: this.indicador,
        relacionados: relacionados,
        documento: this.documento,
      });
    },
    enfocar() {
      this.$refs.nombre.focus();
    },
  },
  async mounted() {
    await this.listarAdelantados();
    await this.generarOptionsOrigenDatos();
    if (this.relacionados) {
      this.indicadorAdelantadoId = this.relacionados[0];
    }
  },
};
</script>
